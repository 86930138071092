<mat-dialog-content>
  <div class="dialog"
    fxLayout="column"
    fxLayoutAlign="center center">

    <!-- close x -->
    <i (click)="close()"
      class="closex fas fa-times"></i>

    <div class="dialog-content"
      fxLayout="column"
      fxLayoutAlign="start stretch">

      <div fxLayout="row"
        fxLayoutAlign="center center"
        style="position: relative;">
        <app-notificationbell *ngIf="UserService.isLoggedIn"></app-notificationbell>
      </div>

      <div *ngIf="!UserService.isLoggedIn"
        style="margin-top: 60px;"
        fxLayoutAlign="center center"
        class="profile">
        <app-login></app-login>
      </div>

      <div *ngIf="UserService.isLoggedIn"
        class="profile-menu"
        fxLayout="column">
        <div class="menu-navigation-item"
          *ngFor="let item of profileMenuItems">
          <app-navigation-button [smallicon]="true"
            [navigationItem]="item"></app-navigation-button>
        </div>
        <div class="divider"></div>
      </div>

      <div class="second-menu"
        fxLayout="column">
        <div class="menu-navigation-item"
          *ngFor="let item of secondMenuItems">
          <app-navigation-button [smallicon]="true"
            [navigationItem]="item"></app-navigation-button>
        </div>
        <div class="divider"></div>
      </div>

      <div class="social-menu"
        fxLayout="column">
        <div class="menu-navigation-item"
          *ngFor="let item of socialMenuItems">
          <app-navigation-button [smallicon]="true"
            [navigationItem]="item"></app-navigation-button>
        </div>
        <div class="divider"></div>
      </div>

      <div class="menu-footer">
        <div *ngFor="let item of MenuStructureService.peripheralMenuStructure"
          [routerLink]="item.target"
          (click)="goto()"
          class="menu-footer-item">
          {{item.caption}}
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>