import { Component } from '@angular/core'
import { NotificationService } from '@service/notification.service'
import { UserNotification } from '@model/user-notification/user-notification'
import { environment } from '@environments/environment'
import { StateService } from '@service/state.service'

@Component({
  selector: 'app-notificationbell',
  templateUrl: './notificationbell.component.html',
  styleUrls: ['./notificationbell.component.scss'],
})
export class NotificationbellComponent {

  public environment = environment

  constructor(
    public NotificationService: NotificationService,
    public StateService: StateService,
  ) { }

  public click(notification: UserNotification): void {
    this.NotificationService.markOneAsRead(notification.id)
    this.NotificationService.execNotificationAction(notification.action)
  }

  public onClickedOutside(event) {
    if (this.NotificationService.notificationsopen)
      this.NotificationService.closeNotifications()
  }
}
