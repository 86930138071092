import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog"
import { MatFormFieldModule } from "@angular/material/form-field"
import { UserImageComponent } from "@component/user-image/user-image.component"
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { NgxLoadingModule } from 'ngx-loading'
import { faFacebookF, faTwitter, faRedditAlien, faLinkedinIn, faGooglePlusG, faTumblr, faPinterestP, faWhatsapp, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faLine } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { SmallRoundBtnComponent } from "@component/small-round-btn/small-round-btn.component"
import { InfoButtonComponent } from "@component/info-button/info-button.component"
import { LinesWordComponent } from "@component/generic/lines-word/lines-word.component"
import { MatSelectModule } from "@angular/material/select"
import { MatInputModule } from '@angular/material/input'
import { TranslateModule } from "@ngx-translate/core"
import { AutoresizeDirective } from "@directive/autoresize.directive"
import { ConfirmDialogComponent } from "@modal/confirm-dialog/confirm-dialog.component"
import { LoginClickGuardDirective } from '@directive/login-click-guard/login-click-guard.directive'
import { MatCheckboxModule } from "@angular/material/checkbox"
import { FooterComponent } from "@component/footer/footer.component"
import { HeaderComponent } from "@component/header/header.component"
import { LoginComponent } from "@component/header/login/login.component"
import { LoginRegistrationDialogComponent } from "@modal/login-registration-dialog/login-registration-dialog.component"
import { LoginDialogComponent } from "@modal/login-registration-dialog/login-dialog/login-dialog.component"
import { RegistrationDialogComponent } from "@modal/login-registration-dialog/registration-dialog/registration-dialog.component"
import { AgbNewsletterComponent } from "@component/agb-newsletter-checkbox/agb-newsletter.component"
import { SsoLoginButtonsComponent } from "@component/sso-login-buttons/sso-login-buttons/sso-login-buttons.component"
import { RouterModule } from "@angular/router"
import { Angulartics2Module } from "angulartics2"
import { HttpClientModule } from "@angular/common/http"
import { ToastrModule } from "ngx-toastr"
import { NgClickOutsideModule } from "ng-click-outside2"
import { AautilModule } from "aautil"
import { MatUtilModule } from "mat-util"
import { MatNativeDateModule } from "@angular/material/core"
import { AboutUsComponent } from "@component/peripherals/about-us/about-us.component"
import { ContactComponent } from "@component/peripherals/contact/contact.component"
import { CareerComponent } from "@component/peripherals/career/career.component"
import { AgbComponent } from "@component/peripherals/agb/agb.component"
import { PrivacyComponent } from "@component/peripherals/privacy/privacy.component"
import { ImpressumComponent } from "@component/peripherals/impressum/impressum.component"
import { NavigationButtonComponent } from "@component/navigation-button/navigation-button.component"
import { NotificationbellComponent } from "@component/notificationbell/notificationbell.component"
import { MessageDialogComponent } from "@modal/message-dialog/message-dialog.component"
import { ForgotPasswordDialogComponent } from "@modal/forgot-password-dialog/forgot-password-dialog.component"
import { DsgvoBannerComponent } from "@component/dsgvo-banner/dsgvo-banner.component"
import { IntroductionDialogComponent } from "@modal/introduction-dialog/introduction-dialog.component"
import { BurgerDialogComponent } from "@modal/burger-dialog/burger-dialog.component"
import { RouterOutletComponent } from "@component/router-outlet-component"
import { FacebookDialogComponent } from "@modal/login-registration-dialog/facebook-dialog/facebook-dialog.component"
import { RouteIonWrapperComponent } from "@component/route-ion-wrapper/route-ion-wrapper.component"
import { AppNagDialogComponent } from "@modal/app-nag-dialog/app-nag-dialog.component"
import { ProfileSelectorComponent } from "@component/profile-selector/profile-selector.component"
import { MatRadioModule } from "@angular/material/radio"
import { MatExpansionModule } from "@angular/material/expansion"

/*
  everything you state here will be always loaded because each other module imports it
*/
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    LoginRegistrationDialogComponent,
    LoginDialogComponent,
    RegistrationDialogComponent,
    FacebookDialogComponent,
    BurgerDialogComponent,
    AgbNewsletterComponent,
    SsoLoginButtonsComponent,
    UserImageComponent,
    SmallRoundBtnComponent,
    InfoButtonComponent,
    LinesWordComponent,
    AutoresizeDirective,
    LoginClickGuardDirective,
    ConfirmDialogComponent,
    AppNagDialogComponent,
    AboutUsComponent,
    ContactComponent,
    CareerComponent,
    AgbComponent,
    PrivacyComponent,
    ImpressumComponent,
    NavigationButtonComponent,
    NotificationbellComponent,
    RouteIonWrapperComponent,
    // ProfileLevelupComponent,
    // BookmarksComponent,
    // BookmarkButtonComponent,
    MessageDialogComponent,
    ForgotPasswordDialogComponent,
    DsgvoBannerComponent,
    // ZimGMapComponent,
    IntroductionDialogComponent,
    RouterOutletComponent,
    ProfileSelectorComponent,
  ],
  imports: [
    RouterModule,

    Angulartics2Module,
    HttpClientModule,
    ToastrModule,
    NgClickOutsideModule,

    AautilModule,
    MatUtilModule,

    MatNativeDateModule,

    // AgmCoreModule,

    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FlexLayoutModule,
    NgxLoadingModule,
    FontAwesomeModule,
    TranslateModule,

    MatFormFieldModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    LoginRegistrationDialogComponent,
    LoginDialogComponent,
    RegistrationDialogComponent,
    FacebookDialogComponent,
    BurgerDialogComponent,
    AgbNewsletterComponent,
    SsoLoginButtonsComponent,
    UserImageComponent,
    SmallRoundBtnComponent,
    InfoButtonComponent,
    LinesWordComponent,
    AutoresizeDirective,
    LoginClickGuardDirective,
    ConfirmDialogComponent,
    AppNagDialogComponent,
    AboutUsComponent,
    ContactComponent,
    CareerComponent,
    AgbComponent,
    PrivacyComponent,
    ImpressumComponent,
    NavigationButtonComponent,
    NotificationbellComponent,
    RouteIonWrapperComponent,
    // ProfileLevelupComponent,
    // BookmarksComponent,
    // BookmarkButtonComponent,
    MessageDialogComponent,
    ForgotPasswordDialogComponent,
    DsgvoBannerComponent,
    // ZimGMapComponent,
    IntroductionDialogComponent,
    RouterOutletComponent,
    ProfileSelectorComponent,

    RouterModule,
    Angulartics2Module,
    HttpClientModule,
    ToastrModule,
    NgClickOutsideModule,

    AautilModule,
    MatUtilModule,

    MatNativeDateModule,

    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FlexLayoutModule,
    NgxLoadingModule,
    FontAwesomeModule,
    TranslateModule,

    MatFormFieldModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} }
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {

    library.addIcons(faFacebookF, faTwitter, faRedditAlien, faLinkedinIn, faGooglePlusG, faTumblr, faPinterestP, faWhatsapp, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faLine)

    library.addIcons(faEnvelope)
  }
}
